import "bootstrap"
import $ from "jquery"

function refreshTooltips() {
  $("*[data-toggle=tooltip]").tooltip()
}


function refreshToasts() {
  $('.toast').toast({
    delay: 5000
  }).toast('show')
}

$(document).on("turbo:load", function(){
  refreshTooltips()
  refreshToasts()
})
