import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import $ from "jquery"

export default class extends Controller {
  static values = {
    value: String,
    target: String,
    perform: String
  }

  get inputTarget() { return document.querySelector(this.targetValue) }

  connect() {
    if (this.performValue) {
      console.log("Trying to perform", this.performValue)
      const method = this[this.performValue]

      if (method) {
        method.call(this)
      }

      this.element.remove()
    }
  }

  updateValue() {
    this.inputTarget.value = this.valueValue
    this.inputTarget.dispatchEvent(new Event("change"))
  }
}