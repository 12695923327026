import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    baseSelector: String,
    options: Object,
    initial: String
  }

  get baseTarget() { return document.querySelector(this.baseSelectorValue) }
  get baseValue() { return this.baseTarget.value }

  connect() {
    this.addBaseElementListener()
    this.update()

    this.selectInitialValue()
  }

  disconnect() {
    this.removeBaseElementListener()
  }

  addBaseElementListener() {
    this.baseTarget.addEventListener("change", this.handleChange)
  }
  removeBaseElementListener() {
    this.baseTarget.removeEventListener("change", this.handleChange)
  }

  handleChange = () => this.update()

  update() {
    const { baseValue } = this
    const activeOption = this.optionsValue[this.baseValue.toString()]

    this.element.disabled = activeOption.disabled
    this.element.innerHTML = activeOption.options || ""
  }

  selectInitialValue() {
    const options = this.element.options || []

    Array.from(options).forEach((optionEl) => {
      if (optionEl.value == this.initialValue) {
        optionEl.selected = true
      }
    })
  }
}