import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "search", "resultsFrame", "result"]

  static values = {
    url: String,
    param: { type: String, default: "q" },
    activeResultIndex: Number,
    selectStrategy: { type: String, default: "event" }
  }

  get inputValue() { return this.searchTarget.value }

  connect() {
    this.resetActiveResult()
  }

  update() {
    const { inputValue } = this

    this.updateResults(inputValue)
  }

  updateResults(query) {
    const options = {
      query: {
        q: query
      }
    }
    const url = this.urlValue
    const param = this.paramValue

    const params = new URLSearchParams()
    params.append(param, query)

    this.resultsFrameTarget.src = `${url}?${params.toString()}`
    this.resetActiveResult()
  }

  handleHotkeys(e) {
    const { key } = e

    if (key == "ArrowUp") {
      this.activeResultIndexValue = Math.max(this.activeResultIndexValue - 1, -1)
    } else if (key == "ArrowDown") {
      this.activeResultIndexValue = Math.min(this.activeResultIndexValue + 1, this.resultTargets.length - 1)
    } else if (key == "Enter") {
      this.selectActiveResult()
    } else {
      return
    }

    e.preventDefault()
  }

  activeResultIndexValueChanged() {
    this.updateHighlightedResult()
  }

  resetActiveResult() {
    this.activeResultIndexValue = -1
  }

  updateHighlightedResult() {
    const activeIndex = this.activeResultIndexValue
    this.resultTargets.forEach((result, idx) => {
      const active = idx == activeIndex

      result.classList.toggle("active", active)
    })
  }

  selectActiveResult() {
    const activeIndex = this.activeResultIndexValue

    if (activeIndex >= 0) {
      const target = this.resultTargets[activeIndex]

      if (target) {
        this.selectResult(target)
      }

      this.resetActiveResult()
    }
  }

  select(e) {
    e.preventDefault()

    this.selectResult(e.currentTarget)
  }

  selectResult(target) {
    const value = target.dataset.autocompleteValue
    const strategy = this.selectStrategyValue

    if (strategy == "event") {
      target.dispatchEvent(new Event("autocomplete:select"))
    } else if (strategy == "input") {
      this.inputTarget.value = value
      this.inputTarget.dispatchEvent(new Event("change"))
    }
  }
}