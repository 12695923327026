import { Controller } from '@hotwired/stimulus'
import $ from "jquery"

export default class extends Controller {
  static values = {
    targetId: String,
    targetValues: Array,
    strategy: String
  }

  get targetElement() { return document.getElementById(this.targetIdValue) }
  get targetValue() { return $(this.targetElement).val() }

  get targetMatchesValue() { return this.targetValuesValue.find((value) => value == this.targetValue) }

  connect() {
    this.targetElement.addEventListener("change", this.handleChange)

    this.update()
  }

  disconnect() {
    this.targetElement.removeEventListener("change", this.handleChange)
  }

  handleChange = () => this.update()

  update() {
    if (this.targetMatchesValue) {
      this.element.disabled = false
    } else {
      this.element.disabled = true
    }
  }
}