import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "search", "selected", "detailsFrame"]

  static values = {
    delivererUrl: String
  }

  get inputValue() { return this.inputTarget.value }
  get hasId() { return !!this.inputValue }

  connect() {
    this.updateView()
  }

  updateView() {
    this.searchTarget.classList.toggle("d-none", this.hasId)
    this.selectedTarget.classList.toggle("d-none", !this.hasId)

    if (this.hasId) {
      this.updateDetailsFrame()
    }
  }

  updateDetailsFrame() {
    const url = this.delivererUrlValue.replace("__ID__", this.inputValue)

    this.detailsFrameTarget.innerHTML = ""
    this.detailsFrameTarget.src = url
  }

  reset(e) {
    if (e) { e.preventDefault() }

    this.inputTarget.value = ""
    this.updateView()
  }
}