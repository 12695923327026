import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import $ from "jquery"

export default class extends Controller {
  static values = {
    target: String,
    perform: String
  }

  get modalTarget() {
    if (this.targetValue){
      return document.querySelector(this.targetValue)
    } else {
      return this.element.closest(".modal")
    }
  }
  get $modal() { return $(this.modalTarget) }

  connect() {
    if (this.performValue) {
      this.perform(this.performValue)
      this.element.remove()
    }
  }

  open() {
    this.perform("show")
  }

  perform(action) {
    console.log("Performing", action, "on", this.$modal, this.targetValue)
    this.$modal.modal(action)
  }
}