import { Controller } from '@hotwired/stimulus'
import $ from 'jquery'

const eventScope = "wizard-controls"

export default class extends Controller {
  static targets = ['controls', 'push']

  get $controls() { return $(this.controlsTarget) }
  get $push() { return $(this.pushTarget) }

  connect() {
    this._updatePushHeightLater()
    this._attachListeners()
  }

  disconnect() {
    this._detachListeners()
  }

  _updatePushHeightLater() {
    setTimeout(() => this._updatePushHeight(), 1)
  }

  _updatePushHeight() {
    const height = this.$controls.height()

    this.$push.height(`${height}px`)
  }

  _scopedEvent(event) {
    return `${event}.${eventScope}`
  }

  _attachListeners() {
    $(window).on(this._scopedEvent("resize"), () => this._updatePushHeight())
  }

  _detachListeners() {
    $(window).off(this._scopedEvent("resize"))
  }
}