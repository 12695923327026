

import { library } from '@fortawesome/fontawesome-svg-core'


import { faCheck as fasCheck, faTimes as fasTimes, faClock as fasClock, faHome as fasHome, faQuestion as fasQuestion, faExclamationTriangle as fasExclamationTriangle, faPlus as fasPlus, faPencilAlt as fasPencilAlt, faPrint as fasPrint, faTrash as fasTrash, faTimesCircle as fasTimesCircle, faWeightHanging as fasWeightHanging, faLeaf as fasLeaf, faUser as fasUser, faMagic as fasMagic, faFileInvoice as fasFileInvoice, faStar as fasStar, faUndo as fasUndo, faClipboardList as fasClipboardList, faTruckLoading as fasTruckLoading, faHammer as fasHammer, faFlask as fasFlask, faEuroSign as fasEuroSign, faRulerCombined as fasRulerCombined, faTree as fasTree, faMoneyBillWave as fasMoneyBillWave, faCaretLeft as fasCaretLeft, faCaretRight as fasCaretRight, faSearch as fasSearch, faSpinner as fasSpinner, faPhone as fasPhone, faEnvelope as fasEnvelope } from "@fortawesome/free-solid-svg-icons"




export function setup() {
  
  library.add(fasCheck);
  
  library.add(fasTimes);
  
  library.add(fasClock);
  
  library.add(fasHome);
  
  library.add(fasQuestion);
  
  library.add(fasExclamationTriangle);
  
  library.add(fasPlus);
  
  library.add(fasPencilAlt);
  
  library.add(fasPrint);
  
  library.add(fasTrash);
  
  library.add(fasTimesCircle);
  
  library.add(fasWeightHanging);
  
  library.add(fasLeaf);
  
  library.add(fasUser);
  
  library.add(fasMagic);
  
  library.add(fasFileInvoice);
  
  library.add(fasStar);
  
  library.add(fasUndo);
  
  library.add(fasClipboardList);
  
  library.add(fasTruckLoading);
  
  library.add(fasHammer);
  
  library.add(fasFlask);
  
  library.add(fasEuroSign);
  
  library.add(fasRulerCombined);
  
  library.add(fasTree);
  
  library.add(fasMoneyBillWave);
  
  library.add(fasCaretLeft);
  
  library.add(fasCaretRight);
  
  library.add(fasSearch);
  
  library.add(fasSpinner);
  
  library.add(fasPhone);
  
  library.add(fasEnvelope);
  
}
