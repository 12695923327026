// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import $ from 'jquery'

export default class ReceiptPaymentMethodController extends Controller {
  static targets = [ "paymentMethod", "ibanField" ]

  get $ibanField() { return $(this.ibanFieldTarget)}

  connect() {
    if (this._showIBANField()) {
      this.$ibanField.show()
    } else {
      this.$ibanField.hide()
    }
  }

  changed() {
    if (this._showIBANField()) {
      this.$ibanField.slideDown()
    } else {
      this.$ibanField.slideUp()
    }
  }

  _showIBANField() {
    return this.paymentMethodTarget.value == "bank_transfer"
  }
}
